<template>
  <b-card-body>
    <b-row>
      <b-col>
        <item-statistics
          title="Beklemede"
          :value="counters[1] | toNumber"
          color="light-warning"
          icon="ClockIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Takip Ediliyor"
          :value="counters[2] | toNumber"
          color="light-info"
          icon="EyeIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Onay Bekliyor"
          :value="counters[4] | toNumber"
          color="light-danger"
          icon="FlagIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Çözümlendi"
          :value="counters[3] | toNumber"
          color="light-success"
          icon="CheckCircleIcon"
        />
      </b-col>
      <b-col
        cols="auto"
        class="text-right"
      >
        <b-button
          to="crm/search"
          variant="primary"
        >
          <FeatherIcon icon="PlusIcon" />
          Başvuru Oluştur
        </b-button>
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BButton,
  BCardBody,
  BCol,
  BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ItemStatistics from '@/views/Crm/components/ItemStatistics'

export default {
  name: 'Counters',
  components: {
    BCardBody,
    BRow,
    BCol,
    BButton,
    ItemStatistics,
  },
  computed: {
    counters() {
      return this.$store.getters['crmRequests/getRequestsCounters']
    },
  },
}
</script>

<style scoped>

</style>
