<template>
  <b-modal
    v-model="requestModal.status"
    size="lg"
    centered
    title="Başvuru Özeti"
  >
    <b-row class="match-height">
      <b-col
        cols="12"
        md="4"
      >
        <b-list-group class="font-small-3 h-100">
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Başvuru No
            </div>
            <div>
              {{ requestModal.data.id }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Marka Departman
            </div>
            <div class="text-warning font-weight-bold font-small-3">
              {{ requestModal.data.brand }} {{ requestModal.data.location? ' - ' + requestModal.data.location : '' }}
            </div>
            <div>
              <span
                v-if="requestModal.data.department1"
              >
                {{ requestModal.data.department1 }}
              </span>
              <span
                v-if="requestModal.data.department2"
              > -
                {{ requestModal.data.department2 }}
              </span>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Atanan Yetkililer
            </div>
            <div>
              <span
                v-if="requestModal.data.username1"
              >
                {{ requestModal.data.username1 }}
              </span>
              <span
                v-if="requestModal.data.username2"
              >
                -
                {{ requestModal.data.username2 }}
              </span>
              <span
                v-if="requestModal.data.username3"
              >
                -
                {{ requestModal.data.username3 }}
              </span>
            </div>
          </b-list-group-item>
          <b-list-group-item v-if="requestModal.data.confirmation_user">
            <div class="text-primary font-weight-bold">
              Onay Yetkilisi
            </div>
            <div>
              {{ requestModal.data.confirmation_user }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Konu
            </div>
            <div>
              {{ requestModal.data.subject1 }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Başvuru Tipi
            </div>
            <div>
              {{ requestModal.data.feedback_type }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Başvuru Kaynağı
            </div>
            <div>
              {{ requestModal.data.crm_applysource }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Başvuru İçeriği
            </div>
            <div class="bg-light border shadow p-1 rounded mt-1 height-400 overflow-scroll">
              <div v-html="requestModal.data.content" />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-block d-flex align-items-center justify-content-between">
        <div class="text-muted font-small-2">
          Başvuru {{ moment(requestModal.data.created).format('llll') }} tarihinde oluşturuldu.
        </div>
        <b-button
          variant="danger"
          :to="'/crm/view/' + requestModal.data.id"
        >
          <FeatherIcon icon="EyeIcon" /> Başvuruyu Görüntüle
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BListGroup, BListGroupItem, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'PreviewModal',
  components: {
    BListGroup, BListGroupItem, BRow, BCol, BButton,
  },
  computed: {
    requestModal() {
      return this.$store.getters['crmRequests/requestModal']
    },
  },
}
</script>
