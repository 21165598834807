<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <request-filter
          :action-methods="searchData"
          :action-filter="getDataList"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card no-body>
        <counters />
        <crm-table />
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardFooter,
  BCol,
  BPagination,
  BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import RequestFilter from '@/views/Crm/Index/RequestFilter'
// eslint-disable-next-line import/extensions
import Counters from '@/views/Crm/Index/Counters'
// eslint-disable-next-line import/extensions
import CrmTable from '@/views/Crm/Index/CrmTable'

export default {
  name: 'Pending',
  components: {
    BCard,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    RequestFilter,
    Counters,
    CrmTable,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      dataQuery: {
        select: [
          'com_crm.id AS id',
          'com_customer.name AS name',
          'com_customer.company_name AS company',
          'com_brand.name AS brand',
          'department1.title AS department',
          'com_cars.license_plate AS car_license_plate',
          'com_crm_status.title AS crm_status',
          'com_user.name AS username',
          'user1.name AS username1',
          '0 AS openDays',
          'com_crm.created AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['crmRequests/getFilterData']
    },
    dataCount() {
      return this.$store.getters['crmRequests/getRequestsCount']
    },
    quickSearch() {
      return this.$store.getters['crmRequests/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.filterData.detailSearch = true
    this.filterData.id_com_crm_status = ['4']
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_crm.id': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_customer.phone': this.quickSearch.keyword,
          'com_cars.license_plate': this.quickSearch.keyword,
        }
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    async setFilterData() {
      const params = this.filterData
      const where = {}
      const orWhereIn = []
      const orWhereInGroup = []
      if (params.sdate) {
        where['DATE(com_crm.created) >='] = params.sdate
      }
      if (params.edate) {
        where['DATE(com_crm.created) <='] = params.edate
      }
      if (params.id_com_crm_subject.length > 0) {
        orWhereIn.push({ 0: 'com_crm.id_com_crm_subject1', 1: params.id_com_crm_subject })
        orWhereIn.push({ 0: 'com_crm.id_com_crm_subject2', 1: params.id_com_crm_subject })
        orWhereIn.push({ 0: 'com_crm.id_com_crm_subject3', 1: params.id_com_crm_subject })
      }
      if (params.id_com_brand.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_brand', 1: params.id_com_brand })
      }
      if (params.id_com_department.length > 0) {
        orWhereIn.push({ 0: 'com_crm.id_com_department1', 1: params.id_com_department })
        /* orWhereIn.push({ 0: 'com_crm.id_com_department2', 1: params.id_com_department }) */
      }
      if (params.id_com_crm_status.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_crm_status', 1: params.id_com_crm_status })
      }
      if (params.id_com_open_user.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_user', 1: params.id_com_open_user })
      }
      if (params.id_com_user.length > 0) {
        orWhereIn.push({ 0: 'com_crm.id_com_user1', 1: params.id_com_user })
        orWhereIn.push({ 0: 'com_crm.id_com_user2', 1: params.id_com_user })
        orWhereIn.push({ 0: 'com_crm.id_com_user3', 1: params.id_com_user })
      }
      if (params.id_com_crm_applysource.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_crm_applysource', 1: params.id_com_crm_applysource })
      }
      if (params.id_com_feedback_type.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_feedback_type', 1: params.id_com_feedback_type })
      }
      if (params.id_com_location.length > 0) {
        orWhereInGroup.push({ 0: 'com_crm.id_com_location', 1: params.id_com_location })
      }
      if (where) {
        this.dataQuery.where = where
      } else {
        delete this.dataQuery.where
      }
      if (orWhereInGroup.length > 0) {
        this.dataQuery.or_where_in_group = orWhereInGroup
      } else {
        delete this.dataQuery.or_where_in_group
      }
      if (orWhereIn.length > 0) {
        this.dataQuery.or_where_in = orWhereIn
      } else {
        delete this.dataQuery.or_where_in
      }
      if (this.quickSearch.id_com_crm) {
        this.dataQuery.where = {
          'com_crm.id': this.quickSearch.id_com_crm,
        }
      }
      return true
    },
    getDataList() {
      this.setFilterData()
        .then(response => {
          if (response) {
            this.$store.dispatch('crmRequests/requestsList', this.dataQuery)
          }
        })
    },
  },
}
</script>
<style scoped></style>
